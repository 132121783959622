<template>
  <div>
    <public-header />

    <div class="site-content pt-2">
      <div class="bg-white py-3 mb-4">
        <div class="container">
          <h4 class="text-center">Resources</h4>
          <div class="row">
            <div class="col-md-4 mb-3" v-for="i in 3" :key="`resource-${i}`">
              <div class="card border">
                <div class="card-body bg-white text-center">
                  <img src="/theme/rocket.svg" alt="rocket" />
                  <h5 class="my-3">Getting Started</h5>
                  <p class="text-muted">
                    Whether you're new or you're a power user, this article
                    will…
                  </p>
                  <router-link to="" class="btn bg-light-blue btn-sm text-primary"
                    >Read More</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <h4 class="text-center">Knowledge Base</h4>
        <div class="row">
          <div class="col-6 col-md-4 mb-4" v-for="i in 6" :key="`knowledge-${i}`">
            <div class="card">
              <div class="card-body bg-white">
                <h5>
                  <i class="fa fa-shopping-cart me-3 text-success"></i>Billing
                </h5>
                <ul>
                  <li>
                    <router-link to="">Pricing Wizard</router-link>
                  </li>
                  <li>
                    <router-link to="">Square Sync</router-link>
                  </li>
                  <li>
                    <router-link to="">Checkout</router-link>
                  </li>
                  <li>
                    <router-link to="">Offers</router-link>
                  </li>
                </ul>
                <router-link to="" class="text-primary"
                  >20 Articles</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white py-3 mb-4">
        <div class="container">
          <h4 class="text-center mb-4">Keep Learning</h4>
          <div class="row">
            <div class="col-md-4" v-for="i in 3" :key="`resource-${i}`">
              <div class="card border">
                <div class="card-body bg-white text-center">
                  <img src="/theme/rocket.svg" alt="rocket" />
                  <h5 class="my-3">Blogging</h5>
                  <p class="text-muted">
                    Whether you're new or you're a power user, this article
                    will…
                  </p>
                  <router-link to="" class="btn bg-light-blue btn-sm text-primary"
                    >Read More</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="text-center py-5">
              <h4>Still Need Help?</h4>
              <p class="text-muted">
                Our specialists are always happy to help. Contact us during
                standard business hours or email us 24/7, and we'll get back to
                you.
              </p>
              <div>
                <router-link to="" class="btn btn-sm btn-primary me-3">Visit Our Community</router-link>
                <router-link :to="{ name: 'contact-us' }" class="btn btn-sm btn-primary">Contact Us</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <public-footer />
  </div>
</template>

<script>
import PublicHeader from "@/components/site/PublicHeader.vue";
import PublicFooter from "@/components/site/PublicFooter.vue";

export default {
  components: {
    PublicHeader,
    PublicFooter,
  },

  mounted() {
    if(this.$store.getters.getAuthUser.name != undefined) {
      this.$router.push({ name: 'dashboard' })
    }
  },
};
</script>
